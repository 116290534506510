import { GlobalContext, Http } from '@sgmo/shared';

export const PresentationService = {
  getClientScope: (
    abortSignal: AbortSignal,
    globalContext: GlobalContext,
  ): Promise<string> =>
    Http.get(
      'presentation/preferences/client-scope',
      [],
      abortSignal,
      globalContext,
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(`API responded with error status ${response.status}`),
        );
      }
      return response.text();
    }),
  getClientScopes: (
    abortSignal: AbortSignal,
    globalContext: {
      clientScopeCode: string,
      sgConnect: {getAuthorizationHeader: () => string | null} | null,
      appConfig: {api: {endpoint: string}} | null
    },
  ): Promise<{ clientScopes: XOneClientScope[] }> =>
    Http.get('presentation/client-scopes', [], abortSignal, globalContext).then(
      (response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(`API responded with error status ${response.status}`),
          );
        }
        return response.json();
      },
    ),

  setClientScope: (
    newClientScopeCode: string,
    abortSignal: AbortSignal,
    globalContext: GlobalContext,
  ): Promise<void> =>
    Http.put(
      'presentation/preferences/client-scope',
      [['clientScope', newClientScopeCode]],
      null,
      abortSignal,
      globalContext,
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(`API responded with error status ${response.status}`),
        );
      }
      return Promise.resolve();
    }),
  getPermissions: (
    abortSignal: AbortSignal,
    globalContext: {
      clientScopeCode: string,
      sgConnect: {getAuthorizationHeader: () => string | null} | null,
      appConfig: {api: {endpoint: string}} | null
    },
  ): Promise<Permissions> =>
    Http.get('presentation/permissions', [], abortSignal, globalContext).then(
      (response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(`API responded with error status ${response.status}`),
          );
        }
        return response.json();
      },
    ),


  searchAllStaticDatas: (
    search: string,
    abortSignal: AbortSignal,
    globalContext: GlobalContext,
    maxNumberOfResults = 10,
  ): Promise<StaticDataSearchResults> =>
    Http.get(
      'presentation/static-datas/search',
      [
        ['s', search],
        ['m', maxNumberOfResults.toString()],
      ],
      abortSignal,
      globalContext,
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(`API responded with error status ${response.status}`),
        );
      }
      return response.json();
    }),
};
