import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import type { GlobalContext } from '@sgmo/shared';
import { Sgb4Spinner } from '@/components/common/Sgb4Spinner';
import { Header } from '@/components/common/Header';
import { HelpCenter } from '@/components/sgwt/HelpCenter';
import { MiniFooter } from '@/components/sgwt/MiniFooter';
import { PageTracker } from '@/components/common/PageTracker';
import { ResetScrollHandler } from '@/components/common/ResetScrollHandler';
import { IntlProvider } from '@/context/IntlContext';
import { UserContext } from '@/context/UserContext';
import './App.scss';
import { CollapseProvider } from '@/components/common/CollapseProvider';
import { ToasterProvider } from '@/components/common/ToasterProvider';
import { ConfirmationModalProvider } from '@/components/common/ConfirmationModalProvider';
import { SplashScreen } from '@/components/common/SplashScreen';
import { PresentationService } from '@/services/PresentationService';
import { LoadingRenderComponent } from '@/components/common/LoadingRenderComponent';
import { getConfig } from '@/config/config';

const HomeLazy = lazy(() =>
  import('@/components/routes/Home/Home').then(({ Home }) => ({
    default: Home,
  })),
);
const TradeCaptureRouterLazy = lazy(() =>
  import('@/components/routes/TradeCapture/TradeCaptureRouter').then(
    ({ TradeCaptureRouter }) => ({
      default: TradeCaptureRouter,
    }),
  ),
);
const PageNotFoundLazy = lazy(() =>
  import('@/components/common/PageNotFound').then(({ PageNotFound }) => ({
    default: PageNotFound,
  })),
);

interface AppProps {
  sgConnect: SGWTConnectCore;
}

const App: React.FC<AppProps> = (props) => {
  const matomo = createInstance({
    urlBase: getConfig().matomo.url,
    siteId: getConfig().matomo.siteId,
    trackerUrl: `${getConfig().matomo.url}/piwik.php`,
    srcUrl: `${getConfig().matomo.url}/piwik.js`,
  });

  const [clientScopeCode, setClientScopeCode] = React.useState<string>('');
  const [permissions, setPermissions] = React.useState<Permissions | null>(
    null,
  );
  const [loadingPermission, setLoadingPermission] =
    React.useState<boolean>(true);
  const canEditPartyCrm = useMemo(
    () => permissions?.permissions.partyCrm.canWrite ?? false,
    [permissions?.permissions.partyCrm.canWrite],
  );

  const globalContext: GlobalContext = {
    clientScopeCode,
    setClientScope: setClientScopeCode,
    permissions,
    sgConnect: props.sgConnect,
    appConfig: getConfig(),
  };

  const redirectToSgwtUnauthorized = () => {
    window.location.href = `localhost:/3000?redirectUri=${window.location.href}`;
  };

  useEffect(() => {
    const abortController = new AbortController();
    PresentationService.getPermissions(abortController.signal, globalContext)
      .then((gottenPermissions) => {
        console.log(gottenPermissions);
        setPermissions(gottenPermissions);
        setClientScopeCode(gottenPermissions.clientScope);
        console.info(
          `Client-scope ${gottenPermissions.clientScope} defined...`,
        );
      })
      .catch((error) => {
        console.error('Failed to get your permissions', error);
      })
      .finally(() => {
        setLoadingPermission(false);
      });
  }, []);

  useEffect(() => {
    if (
      loadingPermission === false &&
      (clientScopeCode === '' ||
        permissions === null ||
        Object.keys(permissions.permissions.screens).length === 0)
    ) {
      redirectToSgwtUnauthorized();
    }
  }, [loadingPermission]);

  return (
    <React.StrictMode>
      <IntlProvider>
        <UserContext.Provider
          value={{
            globalContext,
          }}
        >
          <ToasterProvider>
            <ConfirmationModalProvider>
              <MatomoProvider value={matomo}>
                <CollapseProvider>
                  <BrowserRouter basename="/">
                    <PageTracker>
                      <LoadingRenderComponent isLoading={loadingPermission}>
                        <Header />
                        <div className="flex-grow-1">
                          <Suspense
                            fallback={
                              <div className="m-5 d-flex justify-content-center">
                                <Sgb4Spinner />
                              </div>
                            }
                          >
                            <Switch>
                              <Route exact path="/" component={HomeLazy} />
                              <Route
                                path="/trade-capture"
                                component={TradeCaptureRouterLazy}
                              />
                              <Route component={PageNotFoundLazy} />
                            </Switch>
                          </Suspense>
                        </div>
                        <ResetScrollHandler />
                        <HelpCenter />
                        <MiniFooter />
                        <SplashScreen />
                      </LoadingRenderComponent>
                    </PageTracker>
                  </BrowserRouter>
                </CollapseProvider>
              </MatomoProvider>
            </ConfirmationModalProvider>
          </ToasterProvider>
        </UserContext.Provider>
      </IntlProvider>
    </React.StrictMode>
  );
};

export default App;
