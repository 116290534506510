import React from 'react';
import ReactDOM from 'react-dom';
import { setupSgwtConnectWidget } from '@sgmo/shared';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';

import { getConfig } from './config/config';

const sgwtConnect = setupSgwtConnectWidget(getConfig());

if (sgwtConnect.isAuthorized()) {
  console.log('SgConnect is authorized, rendering App...');
  ReactDOM.render(
    <App sgConnect={sgwtConnect} />,
    document.getElementById('root'),
  );
} else if (sgwtConnect.getAuthorizationError()) {
  console.error('SgConnect has authorization error');
  document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
    </div>`;
} else {
  console.log('Requesting authorization to SgConnect...');
  sgwtConnect.requestAuthorization();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept();
}
