import type { DataContractProperty, JsonObj } from '@sgmo/shared-components';
import type { GlobalContext } from '@sgmo/shared';
import { Http } from '@sgmo/shared';

export const BookingService = {
  getContractDescriptions: (
    abortSignal: AbortSignal,
    globalContext: {
      clientScopeCode: string;
      sgConnect: { getAuthorizationHeader: () => string | null } | null;
      appConfig: { api: { endpoint: string } } | null;
    },
  ): Promise<InstrumentContracts[]> =>
    Http.get('booking/deals/descriptions', [], abortSignal, globalContext).then(
      (response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(`API responded with error status ${response.status}`),
          );
        }
        return response.json();
      },
    ),
  getContractDescription: (
    abortSignal: AbortSignal,
    instrumentName: string,
    contractName: string,
    globalContext: {
      clientScopeCode: string;
      sgConnect: { getAuthorizationHeader: () => string | null } | null;
      appConfig: { api: { endpoint: string } } | null;
    },
  ): Promise<DataContractProperty> =>
    Http.get(
      `booking/deals/descriptions/${instrumentName}/${contractName}`,
      [],
      abortSignal,
      globalContext,
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(`API responded with error status ${response.status}`),
        );
      }
      return response.json();
    }),
  getDeal: (
    abortSignal: AbortSignal,
    dealId: string,
    globalContext: {
      clientScopeCode: string;
      sgConnect: { getAuthorizationHeader: () => string | null } | null;
      appConfig: { api: { endpoint: string } } | null;
    },
  ): Promise<JsonObj> =>
    Http.get(`booking/deals/${dealId}`, [], abortSignal, globalContext).then(
      (response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(`API responded with error status ${response.status}`),
          );
        }
        return response.json();
      },
    ),
  book: (
    abortSignal: AbortSignal,
    sessionId: string,
    actionName: string,
    globalContext: GlobalContext,
  ): Promise<Response> =>
    Http.post(
      `booking/deals/trade-sessions/${sessionId}`,
      [['actionName', actionName]],
      {},
      abortSignal,
      globalContext,
    ).then((response) => response),
  getDocumentation: (
    abortSignal: AbortSignal,
    sessionId: string,
    globalContext: GlobalContext,
  ): Promise<any> =>
    Http.get(
      `documentation/trade-sessions/${sessionId}`,
      [],
      abortSignal,
      globalContext,
    ).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(`API responded with error status ${response.status}`),
        );
      }
      console.log('Documentation result: ', response);
      return response.json();
    }),
};
